import { Timestamp } from 'firebase/firestore';

import type { TenantResponse } from '../types/responses/tenant-response';

import DisplayHelper from '../utils/display-helper';

class Tenant {
  id: string;

  name: string;

  contractStartDate?: Timestamp;

  contractEndDate?: Timestamp;

  constructor(response: TenantResponse) {
    this.id = response.u;
    this.name = response.name ?? '';
    this.contractStartDate = response.contractStart;
    this.contractEndDate = response.contractEnd;
  }

  get displayHyphenOrName(): string {
    return DisplayHelper.displayHyphenOrValue(this.name);
  }

  get displayEmptyOrName(): string {
    return DisplayHelper.displayEmptyOrValue(this.name);
  }

  get displayContractStartDate(): string {
    return DisplayHelper.displayDate(this.contractStartDate);
  }

  get displayContractStartAndTimeAndAmPm(): string {
    return DisplayHelper.displayDateAndTimeAndAmPm(this.contractStartDate);
  }

  get displayContractEndDate(): string {
    return DisplayHelper.displayDate(this.contractEndDate);
  }

  get displayContractEndAndTimeAndAmPm(): string {
    return DisplayHelper.displayDateAndTimeAndAmPm(this.contractEndDate);
  }

  get isOutOfContaract(): boolean {
    return !!this.contractEndDate && this.contractEndDate.toDate().getTime() < new Date().getTime();
  }
}

export default Tenant;
