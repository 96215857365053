import type { ReactNode, Dispatch, SetStateAction } from 'react';
import React, { useState, useContext, createContext } from 'react';
import { useMediaQuery } from '@mui/material';

interface Props {
  children: ReactNode;
}

type SetStateType = Dispatch<SetStateAction<boolean>>;

const ColorModeContext = createContext<boolean>(false);
const SetColorModeContext = createContext<SetStateType>(() => undefined);

export const useColorModeCtx = (): boolean => useContext(ColorModeContext);
export const useSetColorModeCtx = (): SetStateType => useContext(SetColorModeContext);

const getInitialMode = (): boolean => {
  const osColorMode: boolean = useMediaQuery('(prefers-color-scheme: dark)');

  if (typeof localStorage === 'undefined') {
    return osColorMode;
  }

  const localStorageValue = localStorage.getItem('isDarkMode');
  if (localStorageValue === 'true') return true;
  if (localStorageValue === 'false') return false;

  return osColorMode;
};

const ColorModeProvider = (props: Props): JSX.Element => {
  const initialMode: boolean = getInitialMode();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(initialMode);
  const { children } = props;

  return (
    <ColorModeContext.Provider value={isDarkMode}>
      <SetColorModeContext.Provider value={setIsDarkMode}>{children}</SetColorModeContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default ColorModeProvider;
