import { doc, getDoc } from 'firebase/firestore';

import type { TenantResponse } from '../../types/responses/tenant-response';

import { db } from '../../libs/firebase';
import customConsole from '../../utils/custom-console';
import TenantFactory from '../../factories/tenant-factory';
import Tenant from '../../models/tenant';

const fetchTenant = async (tenantId: string | null | undefined): Promise<Tenant | undefined> => {
  try {
    const querySnapshot = await getDoc(doc(db, 'owners', `${tenantId}`));
    const response = querySnapshot.data() as TenantResponse;
    if (!response) throw new Error('API error: tenant got null');
    return TenantFactory.instantiateFromResponse(response);
  } catch (error) {
    customConsole.error(error);
    return undefined;
  }
};

export default fetchTenant;
