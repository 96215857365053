import { Timestamp } from 'firebase/firestore';

import type { UserResponse, Provider } from '../types/responses/user-response';
import type { RoleLevel } from '../types/role-level';

import DisplayHelper from '../utils/display-helper';

const defaultProvider: Provider = 'password';

class User {
  id: string;

  name: string;

  kana: string;

  roleLevel: RoleLevel;

  ownerId: string;

  provider: Provider;

  email: string;

  disabled: boolean;

  createdAt?: Timestamp;

  constructor(response: UserResponse) {
    this.id = response.u;
    this.name = response.name ?? '';
    this.kana = response.kana ?? '';
    this.roleLevel = response.roleLevel ?? 0;
    this.ownerId = response.owner ?? '';
    this.provider = response.provider ?? defaultProvider;
    this.email = response.proId ?? '';
    this.disabled = response.disabled ?? false;
    this.createdAt = response.createdAt;
  }

  get displayHyphenOrName(): string {
    return DisplayHelper.displayHyphenOrValue(this.name);
  }

  get displayEmptyOrName(): string {
    return DisplayHelper.displayEmptyOrValue(this.name);
  }

  get displayHyphenOrEmail(): string {
    return DisplayHelper.displayHyphenOrValue(this.email);
  }

  get displayRole(): string {
    return DisplayHelper.roleLevelToJP(this.roleLevel);
  }

  get switchDisplayUserInfo(): string {
    if (this.name) return this.displayHyphenOrName;

    if (this.provider === 'password' && this.email) {
      return this.displayHyphenOrEmail;
    }

    return '';
  }

  get isAdmin(): boolean {
    return this.roleLevel === 3;
  }

  get isOwer(): boolean {
    return this.roleLevel === 2;
  }

  get isCast(): boolean {
    return this.roleLevel === 1;
  }

  get isGuest(): boolean {
    return this.roleLevel === 0;
  }

  get isCastOrMore(): boolean {
    return this.roleLevel >= 1;
  }
}

export default User;
