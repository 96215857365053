import { onAuthStateChanged } from 'firebase/auth';

import type { User } from 'firebase/auth';

import { firebaseAuth } from '../../libs/firebase';

const fetchAuthUser = async (): Promise<User | null> =>
  new Promise<User | null>(resovle => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
      resovle(user);
      unsubscribe();
    });
  });

export default fetchAuthUser;
