import { ja } from 'date-fns/locale';
import { format } from 'date-fns';

import type { Timestamp } from 'firebase/firestore';
import type { RoleLevel } from '../types/role-level';

class DisplayHelper {
  static displayHyphenOrValue = (value: string | number | undefined): string =>
    DisplayHelper.displayExceptionOrValue(value, '-');

  static displayEmptyOrValue = (value: string | number | undefined): string =>
    DisplayHelper.displayExceptionOrValue(value, '');

  static displayExceptionOrValue = (
    value: string | number | undefined,
    exception: string
  ): string =>
    value === undefined ||
    (typeof value === 'string' && value === '') ||
    (typeof value === 'number' && value < 0)
      ? exception
      : value.toString();

  static displayDate = (timeStamp: Timestamp | undefined): string =>
    !timeStamp
      ? '-'
      : format(timeStamp.toDate(), 'yyyy/MM/dd', {
          locale: ja,
        });

  static displayDateAndTimeAndAmPm = (timeStamp: Timestamp | undefined): string =>
    !timeStamp
      ? '-'
      : format(timeStamp.toDate(), 'yyyy/MM/dd HH:mm aa', {
          locale: ja,
        });

  static displayDateAndTime = (timeStamp: Timestamp | undefined): string =>
    !timeStamp
      ? '-'
      : format(timeStamp.toDate(), 'yyyy/MM/dd HH:mm', {
          locale: ja,
        });

  static displayYear = (timeStamp: Timestamp | undefined): string =>
    !timeStamp
      ? '-'
      : format(timeStamp.toDate(), 'yyyy', {
          locale: ja,
        });

  static displayMonth = (timeStamp: Timestamp | undefined): string =>
    !timeStamp
      ? '-'
      : format(timeStamp.toDate(), 'MM', {
          locale: ja,
        });

  static displayDay = (timeStamp: Timestamp | undefined): string =>
    !timeStamp
      ? '-'
      : format(timeStamp.toDate(), 'dd', {
          locale: ja,
        });

  static roleLevelToJP = (roleLevel: RoleLevel): string => {
    switch (roleLevel) {
      case 3:
        return 'システム管理者';
      case 2:
        return '運営会社';
      case 1:
        return 'スタッフ';
      case 0:
        return '選手';
      default:
        return '選手';
    }
  };
}

export default DisplayHelper;
