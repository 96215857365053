import type { TenantResponse } from '../types/responses/tenant-response';

import Tenant from '../models/tenant';

class TenantFactory {
  static instantiateFromResponse = (response: TenantResponse): Tenant => new Tenant(response);

  static instantiateSkelton = (): Tenant => new Tenant({ u: '' });
}

export default TenantFactory;
