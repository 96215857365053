import React, { ReactNode, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ja } from 'date-fns/locale';

const LocalizeDatePickerProvider = (props: { children: ReactNode }): JSX.Element => {
  const { children } = props;
  const localeText = useMemo(
    () => ({
      previousMonth: '前月を表示',
      nextMonth: '次月を表示',
      cancelButtonLabel: 'キャンセル',
      okButtonLabel: '選択',
      dateTimePickerToolbarTitle: '日時を選択',
      openPreviousView: '前の表示を開く',
      openNextView: '次の表示を開く',
    }),
    []
  );
  const dateFormats = useMemo(() => ({ monthAndYear: 'yyyy年 MM月 dd日', year: 'yyyy年' }), []);
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      localeText={localeText}
      dateFormats={dateFormats}
    >
      {children}
    </LocalizationProvider>
  );
};

export default LocalizeDatePickerProvider;
