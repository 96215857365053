import { useEffect, useRef } from 'react';

import { useLocation } from '@reach/router';

const useLocationChange = (callback: () => void): void => {
  const refCallback = useRef<undefined | (() => void)>();
  const currentLocation = useLocation();

  useEffect(() => {
    refCallback.current = callback;
  }, []);

  useEffect(() => {
    if (refCallback.current) {
      refCallback.current();
    }
  }, [currentLocation.pathname]);
};

export default useLocationChange;
