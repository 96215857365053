import { collection, getDocs, query, where } from 'firebase/firestore';

import type { UserResponse } from '../../types/responses/user-response';

import { db } from '../../libs/firebase';
import customConsole from '../../utils/custom-console';
import fetchAuthUser from './fetch-auth-user';
import UserFactory from '../../factories/user-factory';
import User from '../../models/user';

const fetchUserData = async (): Promise<User | null> => {
  try {
    const authUser = await fetchAuthUser();
    if (!authUser) throw new Error(`API error: authUser got null`);
    const { uid } = authUser;
    const q = query(collection(db, 'users'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs.map(doc => doc.data()) as UserResponse[];
    if (userData.length <= 0) throw new Error(`API error: userData got null`);
    const response = userData[0];
    return UserFactory.instantiateFromResponse(response);
  } catch (error) {
    customConsole.log(error);
  }
  return null;
};

export default fetchUserData;
