exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-add-event-tsx": () => import("./../../../src/pages/add-event.tsx" /* webpackChunkName: "component---src-pages-add-event-tsx" */),
  "component---src-pages-add-race-tsx": () => import("./../../../src/pages/add-race.tsx" /* webpackChunkName: "component---src-pages-add-race-tsx" */),
  "component---src-pages-add-tenant-tsx": () => import("./../../../src/pages/add-tenant.tsx" /* webpackChunkName: "component---src-pages-add-tenant-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-certificate-tsx": () => import("./../../../src/pages/certificate.tsx" /* webpackChunkName: "component---src-pages-certificate-tsx" */),
  "component---src-pages-edit-event-tsx": () => import("./../../../src/pages/edit-event.tsx" /* webpackChunkName: "component---src-pages-edit-event-tsx" */),
  "component---src-pages-edit-player-tsx": () => import("./../../../src/pages/edit-player.tsx" /* webpackChunkName: "component---src-pages-edit-player-tsx" */),
  "component---src-pages-edit-race-tsx": () => import("./../../../src/pages/edit-race.tsx" /* webpackChunkName: "component---src-pages-edit-race-tsx" */),
  "component---src-pages-edit-status-tsx": () => import("./../../../src/pages/edit-status.tsx" /* webpackChunkName: "component---src-pages-edit-status-tsx" */),
  "component---src-pages-edit-tenant-tsx": () => import("./../../../src/pages/edit-tenant.tsx" /* webpackChunkName: "component---src-pages-edit-tenant-tsx" */),
  "component---src-pages-event-detail-tsx": () => import("./../../../src/pages/event-detail.tsx" /* webpackChunkName: "component---src-pages-event-detail-tsx" */),
  "component---src-pages-event-detail-view-player-tsx": () => import("./../../../src/pages/event-detail-view-player.tsx" /* webpackChunkName: "component---src-pages-event-detail-view-player-tsx" */),
  "component---src-pages-event-detail-view-result-tsx": () => import("./../../../src/pages/event-detail-view-result.tsx" /* webpackChunkName: "component---src-pages-event-detail-view-result-tsx" */),
  "component---src-pages-event-detail-view-staff-tsx": () => import("./../../../src/pages/event-detail-view-staff.tsx" /* webpackChunkName: "component---src-pages-event-detail-view-staff-tsx" */),
  "component---src-pages-event-list-tsx": () => import("./../../../src/pages/event-list.tsx" /* webpackChunkName: "component---src-pages-event-list-tsx" */),
  "component---src-pages-event-list-view-tsx": () => import("./../../../src/pages/event-list-view.tsx" /* webpackChunkName: "component---src-pages-event-list-view-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-tsx": () => import("./../../../src/pages/landing-page.tsx" /* webpackChunkName: "component---src-pages-landing-page-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-measurement-tsx": () => import("./../../../src/pages/measurement.tsx" /* webpackChunkName: "component---src-pages-measurement-tsx" */),
  "component---src-pages-my-page-tsx": () => import("./../../../src/pages/my-page.tsx" /* webpackChunkName: "component---src-pages-my-page-tsx" */),
  "component---src-pages-player-detail-team-tsx": () => import("./../../../src/pages/player-detail-team.tsx" /* webpackChunkName: "component---src-pages-player-detail-team-tsx" */),
  "component---src-pages-player-detail-team-view-tsx": () => import("./../../../src/pages/player-detail-team-view.tsx" /* webpackChunkName: "component---src-pages-player-detail-team-view-tsx" */),
  "component---src-pages-player-detail-tsx": () => import("./../../../src/pages/player-detail.tsx" /* webpackChunkName: "component---src-pages-player-detail-tsx" */),
  "component---src-pages-player-detail-view-tsx": () => import("./../../../src/pages/player-detail-view.tsx" /* webpackChunkName: "component---src-pages-player-detail-view-tsx" */),
  "component---src-pages-race-detail-tsx": () => import("./../../../src/pages/race-detail.tsx" /* webpackChunkName: "component---src-pages-race-detail-tsx" */),
  "component---src-pages-register-runner-in-batch-tsx": () => import("./../../../src/pages/register-runner-in-batch.tsx" /* webpackChunkName: "component---src-pages-register-runner-in-batch-tsx" */),
  "component---src-pages-register-runner-individually-tsx": () => import("./../../../src/pages/register-runner-individually.tsx" /* webpackChunkName: "component---src-pages-register-runner-individually-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-search-events-tsx": () => import("./../../../src/pages/search-events.tsx" /* webpackChunkName: "component---src-pages-search-events-tsx" */),
  "component---src-pages-tenant-list-tsx": () => import("./../../../src/pages/tenant-list.tsx" /* webpackChunkName: "component---src-pages-tenant-list-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

