const isDevEnvironment = (): boolean => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  return false;
};

interface Console {
  log: (...args: any[]) => void;
  dir: (...args: any[]) => void;
  error: (...args: any[]) => void;
  warn: (...args: any[]) => void;
}

const log = (...args: any[]): void => {
  if (isDevEnvironment()) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

const dir = (...args: any[]): void => {
  if (isDevEnvironment()) {
    // eslint-disable-next-line no-console
    console.dir(...args);
  }
};

const error = (...args: any[]): void => {
  if (isDevEnvironment()) {
    // eslint-disable-next-line no-console
    console.error(...args);
  }
};

const warn = (...args: any[]): void => {
  if (isDevEnvironment()) {
    // eslint-disable-next-line no-console
    console.warn(...args);
  }
};

const customConsole: Console = { log, dir, error, warn };

export default customConsole;
