import type { UserResponse } from '../types/responses/user-response';

import User from '../models/user';

class UserFactory {
  static instantiateFromResponse = (response: UserResponse): User => new User(response);

  static instantiateSkelton = (): User => new User({ u: '' });
}

export default UserFactory;
