/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
//
//

const React = require('react').default;
const AuthProvider = require('./src/contexts/auth-context').default;
const ColorModeProvider = require('./src/contexts/color-mode-context').default;
const { StyledEngineProvider } = require('@mui/material/styles');
const { LocalizationProvider } = require('@mui/x-date-pickers');
const LocalizeDatePickerProvider = require('./src/contexts/localize-date-picker-context').default;
const { AdapterDayjs } = require('@mui/x-date-pickers/AdapterDayjs');
const ja = require('date-fns/locale/ja').default;
const { LocationProvider } = require('@reach/router');

require('./src/common/reset-style.css');
require('simplebar-react/dist/simplebar.min.css');

exports.wrapRootElement = ({ element }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ColorModeProvider>
        <LocationProvider>
          <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
              <LocalizeDatePickerProvider>{element}</LocalizeDatePickerProvider>
            </LocalizationProvider>
          </AuthProvider>
        </LocationProvider>
      </ColorModeProvider>
    </StyledEngineProvider>
  );
};
