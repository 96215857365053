import type { ReactElement } from 'react';

import React, { useEffect, useContext, createContext, useState, useMemo } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import useLocationChange from '../hooks/use-location-change';
import fetchUserData from '../api/user/fetch-user-data';
import fetchTenant from '../api/tenant/fetch-tenant';
import User from '../models/user';
import Tenant from '../models/tenant';
import customConsole from '../utils/custom-console';

interface Props {
  children: ReactElement;
}

interface Auth {
  user: User | undefined;
  currentTenantOfAuthUser: Tenant | undefined;
  isAuthLoading: boolean;
  setUser: (user: User | undefined) => void;
  setCurrentTenantOfAuthUser: (user: Tenant | undefined) => void;
}

const AuthContext = createContext<Auth>({
  user: undefined,
  currentTenantOfAuthUser: undefined,
  isAuthLoading: true,
  setUser: () => {},
  setCurrentTenantOfAuthUser: () => {},
});

export const useAuthContext = (): Auth => useContext(AuthContext);

const AuthProvider = (props: Props): JSX.Element => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [currentTenantOfAuthUser, setCurrentTenantOfAuthUser] = useState<Tenant | undefined>(
    undefined
  );
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const location = useLocation();
  const { children } = props;

  const fetchUser = async (): Promise<void> => {
    try {
      setIsAuthLoading(true);
      setUser(undefined);
      const currentUser = await fetchUserData();
      if (!currentUser) throw new Error('userData not found');
      setUser(currentUser);
      const tenant = await fetchTenant(currentUser.ownerId);
      setCurrentTenantOfAuthUser(tenant);
    } catch (err) {
      customConsole.error(err);
    } finally {
      setIsAuthLoading(false);
    }
  };

  useLocationChange(() => {
    fetchUser();

    const publicPages = ['/', '/login/', '/reset-password/'];
    const isPublicPage = publicPages.some(publicPage => location.pathname === publicPage);

    if (!isPublicPage && !user) {
      customConsole.log('you need to be authorized again.');
      //navigate('/login');
    }
  });

  const value = useMemo<Auth>(
    () => ({
      user,
      currentTenantOfAuthUser,
      isAuthLoading,
      setUser,
      setCurrentTenantOfAuthUser,
    }),
    [user, currentTenantOfAuthUser, isAuthLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
